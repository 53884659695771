<template>
  <div class="login container">
    <b-row align-v="center" align-h="center" class="h-100">
      <b-col md="8" lg="6" align-self="center">
        <b-card class="login-form">
          <b-card-body class="position-relative">
            <button
              type="button"
              class="position-absolute btn-circle btn-back-guest"
              @click="$router.push({ name: 'login' })"
            >
              <i class="tmlps-icn-back h2"></i>
            </button>

            <div class="d-flex align-items-center justify-content-center">
              <Logo :image-url="config.logo" />

              <h1 class="title" v-if="config.name">{{ config.name }}</h1>
              <h1 class="title" v-else>Timelapse <br />México</h1>
            </div>

            <h4 class="text-center mt-4">Recuperar contraseña</h4>
            <p class="text-left my-4">
              Te enviaremos un link por correo electrónico <br />
              donde podrás establecer una nueva contraseña
            </p>

            <div>
              <form @submit.prevent="forgotPassword">
                <div class="mt-3">
                  <EmailInput v-model="email" :error="errors.first('email')" />
                </div>

                <b-alert class="mt-4" :show="errors.has('forgot-password')" variant="danger">{{
                  errors.first('forgot-password')
                }}</b-alert>

                <div class="text-center mt-4">
                  <button class="btn btn-primary px-5" :disabled="loading">Enviar</button>
                </div>
              </form>
            </div>
          </b-card-body>
        </b-card>

        <div class="privacy py-4 fixed-bottom">
          <div class="text-center">Todos los derechos reservados Timelapse México {{anioActual}}</div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';
import Errors from '@/util/Errors';
import Logo from '@/components/Logo';
import EmailInput from '@/components/EmailInput';

export default {
  components: {
    Logo,
    EmailInput,
  },
  data() {
    return {
      email: '',
      loading: false,
      errors: new Errors(),
      anioActual: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState('domain', ['config']),
  },
  methods: {
    forgotPassword() {
      this.loading = true;
      this.errors.clear();

      store
        .dispatch('auth/forgotPassword', {
          email: this.email,
        })
        .then(() => {
          this.$router.replace({ name: 'login' }).catch(() => {});
        })
        .catch((error) => {
          if (error.response && error.response.data.errors) {
            this.errors.capture(error.response.data.errors);
          }
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    document.body.classList.add('login-page');
  },
  destroyed() {
    document.body.classList.remove('login-page');
  },

  async beforeRouteEnter(from, to, next) {
    await store.dispatch('domain/fetchDomain', document.location.host);
    // document.documentElement.style.setProperty('--primary', domain.color);
    next();
  },
};
</script>

<style lang="sass" scoped>
.login
  height: 100vh

.privacy
  color: white
  font-size: 0.875rem

.title
  text-transform: uppercase
  font-size: 1.75rem
  margin-left: 0.5rem
</style>
